import React from "react"
import Title from "../title-component"
import styles from "../../css/historia-component.module.css"
import videoHistoria from "../../assets/historiaPanamaHat.mp4"
import Img from "gatsby-image"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
const gethistoria = graphql`
  query {
    historia1: file(relativePath: { eq: "fondoSombrero9.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    historia2: file(relativePath: { eq: "fabricacionSombrero5.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    historia3: file(relativePath: { eq: "fabricacionSombrero6.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    historia4: file(relativePath: { eq: "calidadSombrero.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const HistoriaPanamaHat = () => {
  const data = useStaticQuery(gethistoria)
  return (
    <div>
      <section className={styles.historia}>
        <Title
          title={<FormattedMessage id="tituloHistoria1" />}
          subtitle={<FormattedMessage id="tituloHistoria2" />}
        />
        <p className={styles.descripcion}></p>
        <p className={styles.descripcion}></p>
        <div className={styles.aboutCenter}>
          <article className={styles.aboutImg}>
            <div className={styles.imgContainer}>
              {/* <img src={img} alt="Abour company" /> */}
              <Img
                fluid={data.historia1.childImageSharp.fluid}
                alt="Historia del panama hat"
              />
            </div>
          </article>
          <article className={styles.aboutInfo}>
            <h4>
              <FormattedMessage id="increibleHistoria" />
            </h4>
            <p className={styles.text}>
              <FormattedMessage id="historia1" />
            </p>
            <p className={styles.text}>
              <FormattedMessage id="historia2" />
            </p>
            <h6 className={styles.unesco}>
              <FormattedMessage id="reconocimiento" />
            </h6>
            <div className={styles.btn}>
              <Link to="/galeria" className={`btn-blue`}>
                <FormattedMessage id="verGaleria" />
              </Link>
            </div>
          </article>
        </div>
      </section>
      <div className={`${styles.aboutCenter2} ${styles.background2}`}>
        <article className={styles.aboutInfo}>
          <h4>
            <FormattedMessage id="tituloComoSeHacePanamaHat" />
          </h4>
          <p className={styles.text}>
            <FormattedMessage id="historia3" />
          </p>
          <p className={styles.text}>
            <FormattedMessage id="historia4" />
          </p>
        </article>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="Abour company" /> */}
            <Img
              fluid={data.historia2.childImageSharp.fluid}
              alt="Historia del sombrero de paja toquilla"
            />
          </div>
        </article>
      </div>

      <div className={`${styles.historia}`}>
        <h3 className={styles.title}>
          <FormattedMessage id="procesoElaboracion" />
        </h3>

        <video controls className={styles.video}>
          <track kind="captions" />
          <source src={videoHistoria} type="video/mp4" />
        </video>
      </div>

      <div className={`${styles.aboutCenter2} ${styles.background2}`}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="Abour company" /> */}
            <Img
              fluid={data.historia3.childImageSharp.fluid}
              alt="Historia del panama hat"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>
            <FormattedMessage id="reconocerPanamaHat" />
          </h4>
          <p className={styles.text}>
            <FormattedMessage id="historia5" />
          </p>
          <p className={styles.text}>
            <FormattedMessage id="historia6" />
          </p>
          <p className={styles.text}>
            <FormattedMessage id="historia7" />
          </p>

          <div className={styles.btn}>
            <Link to="/calidades" className={`btn-blue`}>
              <FormattedMessage id="calidadesSombrero" />
            </Link>
          </div>
        </article>
      </div>
      <div className={styles.aboutCenter}>
        <article className={styles.aboutInfo}>
          <h4>
            <FormattedMessage id="mejorOpcion" />
          </h4>
          <p className={styles.text}>
            <FormattedMessage id="historia8" />
          </p>
          <p className={styles.text}>
            <FormattedMessage id="historia9" />
          </p>
          <div className={styles.btn}>
            <Link to="/contact" className={`btn-blue`}>
              <FormattedMessage id="contacteseConNosotros" />
            </Link>
          </div>
        </article>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="Abour company" /> */}
            <Img
              fluid={data.historia4.childImageSharp.fluid}
              alt="Historia del sombrero de paja toquilla"
            />
          </div>
        </article>
      </div>
      <div className={styles.aboutCenter}></div>
    </div>
  )
}

export default HistoriaPanamaHat
